<template>
	<div data-app>
		<router-view />
		<v-tour
			name="global-tour"
			v-if="getTourSteps.length"
			:steps="getTourSteps"
			:callbacks="getTourCallbacks"
			:options="getTourOptions"
		>
			<template slot-scope="tour">
				<transition name="fade">
					<v-step
						v-if="tour.steps[tour.currentStep]"
						:key="tour.currentStep"
						:step="tour.steps[tour.currentStep]"
						:previous-step="tour.previousStep"
						:next-step="tour.nextStep"
						:stop="tour.stop"
						:skip="tour.skip"
						:is-first="tour.isFirst"
						:is-last="tour.isLast"
						:labels="tour.labels"
					>
						<template v-if="!tour.isLast" style="display:flex;">
							<div data-element="actions" slot="actions">
								<btn
									text="Okay"
									@click.native="tour.nextStep"
									size="sm"
									colour="white"
								/>
							</div>
						</template>
						<template v-if="tour.isLast">
							<div data-element="actions" slot="actions">
								<btn
									text="Okay"
									@click.native="tour.finish"
									size="sm"
									colour="white"
								/>
							</div>
						</template>
					</v-step>
				</transition>
			</template>
		</v-tour>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		name: 'App',
		components: {
			Btn
		},
		metaInfo: {
			title: 'Learn to play bridge',
			titleTemplate: '%s - Bridge128',
			link: [
				{
					rel: 'shortcut icon',
					href: '/favicon.ico',
					color: '#000f76'
				},
				{
					rel: 'icon',
					type: 'image/png',
					sizes: '16x16',
					href: '/icons/favicon-16x16.png'
				},
				{
					rel: 'icon',
					type: 'image/png',
					sizes: '32x32',
					href: '/icons/favicon-32x32.png'
				},
				{
					rel: 'apple-touch-icon',
					sizes: '180x180',
					href: '/icons/apple-touch-icon.png'
				},
				{
					rel: 'mask-icon',
					href: '/icons/safari-pinned-tab.svg',
					color: '#000f76'
				},
				{
					rel: 'msapplication-TileColor',
					content: '#da532c'
				},
				{
					rel: 'msapplication-config',
					content: '/browserconfig.xml'
				},
				{
					rel: 'theme-color',
					content: '#ffffff'
				},
				{
					rel: 'apple-mobile-web-app-title',
					content: 'Bridge128'
				},
				{
					rel: 'application-name',
					content: 'Bridge128'
				},
				{
					rel: 'manifest',
					href: '/site.webmanifest'
				}
			],
			meta: [
				{
					name: 'robots',
					content: process.env.VUE_APP_ENABLE_INDEXING ? 'index,follow' : 'noindex,nofollow'
				},
				{
					name: 'description',
					content: 'Learn or teach bridge online in live or solo courses using ACOL and SAYC bidding systems - for absolute beginners to advanced players.'
				},
				{
					name: 'apple-mobile-web-app-capable',
					content: 'yes'
				},
				{
					name: 'apple-mobile-web-app-title',
					content: 'Bridge128'
				}
			]
		},
		computed: {
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},
			getTourSteps () {
				return this.$store.getters['ui/getTourSteps'];
			},
			getLoadingIsVisible () {
				return this.$store.getters['ui/getLoadingIsVisible'];
			},
			getTourOptions () {
				return {
					useKeyboardNavigation: false,
					labels: {
						buttonNext:      'OK',
						buttonStop:      'OK'
					}
				};
			},
			getTourCallbacks () {
				return {
					onNextStep: this.onTourNextStep,
					onFinish: this.onTourFinish
				};
			}
		},
		watch: {
			getLoadingIsVisible (newVal, oldVal) {
				if (newVal === false
					&& oldVal === true) {
					setTimeout(() => {
						if (!this.$tours?.['global-tour']) {
							console.log('There are no tours');
							return false;
						}
						this.$tours['global-tour'].start();
					}, 1000);
				}
			}
		},
		mounted () {
			this.init().catch(err => console.error(err));
		},
		methods: {
			async init () {
				if (!this.getIsAuthenticated) {
					return;
				}
				const profile = await this.$store.dispatch('auth/fetchUserProfile');
				if (profile) {
					await this.$store.dispatch('auth/updateTimezone');
					await this.$store.dispatch('auth/trackUser');
				}
			},
			onTourNextStep (tourStepIndex) {
				this.$store.dispatch('ui/addSeenTourStepByIndex', {
					tourStepIndex
				});
			},
			onTourFinish () {
				this.$store.commit('ui/addSeenFinalTourStep');
			}
		}
	};

</script>

<style src="@/scss/styles.scss" lang="scss" />
