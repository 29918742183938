import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import qs from 'qs';

export const pluralize = (count, noun, suffix = 's') => `${noun}${count !== 1 ? suffix : ''}`;

export const arrayShift = (arr, shiftCount) => {
	const result = [];
	if (arr.length === 1 || shiftCount === 0) {
		return arr;
	}
	for (let index = 0; index < arr.length; index++) {
		result[(index + shiftCount) % arr.length] = arr[index];
	}
	return result;
};

export const sleep = (delay) => new Promise(resolve => setTimeout(resolve, delay));

export const randomNumber = (min, max) => {
	const r = Math.random() * (max - min) + min;
	return Math.floor(r);
};

export const chunk = (arr, size) =>
	Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
		arr.slice(i * size, i * size + size)
	);

export const createSuitElement = (symbol, colour) => {
	const outerNode = document.createElement('span');
	const innerNode = document.createElement('span');
	innerNode.innerHTML = symbol;
	outerNode.setAttribute('data-colour', colour);
	outerNode.setAttribute('data-element', 'suit-symbol');
	outerNode.innerHTML = innerNode.outerHTML;
	return outerNode.outerHTML;
};

export const toNumber = (value, defaultValue) => {
	if (value) {
		return !isNaN(parseInt(value)) ? parseInt(value) : defaultValue;
	}
	return defaultValue;
};

export const parseToken = (token) => {
	const REFRESH_TOKEN_BEFORE_DAYS = process.env.VUE_APP_REFRESH_TOKEN_BEFORE_DAYS;
	const REFRESH_TOKENS_EARLY = (process.env.VUE_APP_REFRESH_TOKENS_EARLY === '1');

	const decoded = decode(token);

	let tokenExp = null;
	let refreshAfter = null;

	if (decoded.exp && REFRESH_TOKENS_EARLY) {
		tokenExp = new Date(decoded.exp * 1000);

		// a token should be refreshed N days before it expires
		const days = REFRESH_TOKEN_BEFORE_DAYS || 7;
		// the date after which the token should be refreshed
		const refreshAfterDate = new Date(tokenExp);
		refreshAfterDate.setDate(refreshAfterDate.getDate() - days);

		refreshAfter = refreshAfterDate;
	}

	return {
		token,
		tokenExp,
		refreshAfter
	};
};

export const getAuthStateFromCookies = (cookieKey) => {
	const value = Cookies.get(cookieKey);

	if (value) {
		try {
			const parsedValue = JSON.parse(value);

			if (parsedValue?.token) {
				return parseToken(parsedValue.token);
			}

			return parsedValue;
		} catch (error) {
			console.error(error);
		}
	}
};

export const openExternalLink = (path = '', params = {}) => {
	const queryParams = qs.stringify(params, {
		arrayFormat: 'comma'
	});
	const url = `${path}${queryParams ? '?' + queryParams : ''}`;
	window.open(url, '_blank');
};
