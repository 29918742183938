import { camelCase } from 'change-case';
import { parseToken } from '../../helpers';

export default {
	setToken (state, { token }) {
		state.token = token;

		if (token) {
			const { tokenExp, refreshAfter } = parseToken(token);

			state.tokenExp = tokenExp;
			state.refreshAfter = refreshAfter;
			state.tokenRefreshInProgress = false;
		}
	},

	setTokenRefreshInProgress (state, value) {
		state.tokenRefreshInProgress = value;
	},

	logOut (state) {
		state.user = null;
		state.token = null;
		state.tokenExp = null;
		state.refreshAfter = null;
		state.tokenRefreshInProgress = false;
	},

	setProfile (state, { profile }) {
		const formattedProfile = {};
		Object.keys(profile).forEach((key) => {
			formattedProfile[camelCase(key)] = profile[key];
		});
		state.profile = {
			...state.profile,
			...formattedProfile
		};
	},

	clearProfile (state) {
		state.profile = {};
	}

};
