import axios                         from 'axios';
import { includeToken }              from './interceptors';
import { checkTokenIsValid }         from './interceptors';
import { checkTokenExpiry }          from './interceptors';
import { checkProfileSetupRequired } from './interceptors';

const api = axios.create({
	baseURL: process.env.VUE_APP_BRIDGE128_API
});

api.interceptors.request.use(...includeToken);
api.interceptors.request.use(...checkTokenIsValid);
api.interceptors.request.use(...checkTokenExpiry);

api.interceptors.response.use(...checkProfileSetupRequired);

export default api;
